import { useContext, useEffect, useState } from "react";
import { DataContext } from "../context/Data";
import Label from "./Label";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  IconButton 
} from "@chakra-ui/react";
import StatusItem from "./StatusItem";
import DeleteItem from "./DeleteItem";
import { Icon, EditIcon } from "@chakra-ui/icons";

function Data({ edit }) {
  const { data } = useContext(DataContext);
  const [items, setItems] = useState([]);

  useEffect(() => {
      setItems(data);
  }, [data]);

  return (
    <>
      <Table size="md" fontSize="sm">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Título</Th>
            <Th>Promoción</Th>
            <Th>Inicia</Th>
            <Th>Finaliza</Th>
            <Th>Status</Th>
            <Th>Acciones</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item, i) => (
            <Tr key={i}>
              <Td>{item.order}</Td>
              <Td>{item.title}</Td>
              <Td>{item.value} {item.valueText}</Td>
              <Td>{item.start}</Td>
              <Td>{item.end}</Td>
              <Td>
                <Label label={item.state} />
              </Td>
              <Td>
                <Flex gap={2}>
                  <StatusItem id={item.id} state={item.state} />
                  <IconButton 
                    rounded={4}
                    size="md"
                    type="submit"
                    colorScheme="blue"
                    p={2}
                    icon={<Icon as={EditIcon} />}
                    onClick={() => edit(item)}
                    mr={4}
                  />
                  <DeleteItem item={item} />
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

export default Data;
