import { Box, Grid } from "@chakra-ui/react";

function Loyaut({ children }) {
  return (
    <Box background="gray.100" minHeight="100vh" py={24} px={2}>
      <Grid justifyContent="center" templateColumns="minmax(auto,1200px)">
        <Box background="white" p={4}>
          {children}
        </Box>
      </Grid>
    </Box>
  );
}

export default Loyaut;
