import { useState } from "react";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Flex,
  ModalFooter,
} from "@chakra-ui/react";

import SignOut from "./components/SignOut";
import AddItem from "./components/AddItem";
import EditItem from "./components/EditItem";
import Data from "./components/Data";
import Loyaut from "./components/Loyaut";

function Dashboard() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState(null);

  function openModalAddItem() {
    onOpen();
    setModalContent(<AddItem close={onClose} />);
  }

  function openModalEditItem(item) {
    onOpen();
    setModalContent(<EditItem close={onClose} item={item} />);
  }

  return (
    <div>
      <Box position="fixed" top={2} right={2} zIndex={2}>
        <Flex gap={2}>
          <Button
            colorScheme="whatsapp"
            size="sm"
            rounded="2"
            onClick={openModalAddItem}
          >
            Agregar Promoción
          </Button>
          <SignOut />
        </Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>{modalContent}</ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Loyaut>
        <Data edit={openModalEditItem} />
      </Loyaut>
    </div>
  );
}

export default Dashboard;
