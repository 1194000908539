import { useState } from "react";
import { auth } from "./lib/firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

import {
  Flex,
  FormLabel,
  Input,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Image,
  Link,
} from "@chakra-ui/react";

function Login() {
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  function errorCode(err) {
    if (err === "auth/user-not-found" || err === "auth/wrong-password") {
      return "Usuario ó contraseña incorrecta";
    }

    if (err === "auth/invalid-email") {
      return "Email invalido";
    }

    if (err === "auth/missing-password") {
      return "Ingrese su contraseña";
    }

    if (err === "auth/internal-error") {
      return "Error Interno";
    }

    return err;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setAlertMessage(null);
    setAlert(false);
    const { email, password } = event.target.elements;
    try {
      await signInWithEmailAndPassword(auth, email.value, password.value);
    } catch (error) {
      signOut(auth);
      setLoading(false);
      const message = errorCode(error.code);
      setAlertMessage(message);
      setAlert(true);
    }
  }

  return (
    <>
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
        background="gray.100"
        py={6}
        px={2}
        flexDirection="column"
      >
        <Box w="180px" mb={6}>
          <Image src="simplespa.png" alt="Simple Spa" w="100%" />
        </Box>

        <Flex
          direction="column"
          background="white"
          p={10}
          rounded={4}
          boxShadow="lg"
          w="380px"
        >
          <form onSubmit={handleSubmit}>
            <Flex direction="column">
              <FormLabel fontSize="sm">Email:</FormLabel>
              <Input
                placeholder="Ingresa tu correo"
                type="email"
                name="email"
                size="md"
                mb={3}
                rounded={4}
                maxLength={70}
                autoComplete="username"
              />
              <FormLabel fontSize="sm">Password:</FormLabel>
              <Input
                type="password"
                placeholder="********"
                name="password"
                size="md"
                mb={3}
                rounded={4}
                maxLength={16}
                autoComplete="current-password"
              />
              <Button
                colorScheme="blue"
                type="submit"
                loadingText="Verificando"
                isLoading={loading}
                rounded={4}
              >
                Entrar
              </Button>
              {alert ? (
                <Alert status="error" mt={3}>
                  <AlertIcon />
                  <AlertTitle mr={2}>Error:</AlertTitle>
                  <AlertDescription>{alertMessage}</AlertDescription>
                </Alert>
              ) : null}
            </Flex>
          </form>
        </Flex>
        <Link
          href="https://simplespa.mx/"
          isExternal
          mt={6}
          fontSize="sm"
          color="gray.500"
        >
          simplespa.mx
        </Link>
      </Flex>
    </>
  );
}

export default Login;
