import { useRef, useState, useContext } from "react";
import { db } from "../lib/firebase";
import { updateDoc, doc } from "firebase/firestore";
import TextEditor from "./TextEditor";
import { DataContext } from "../context/Data";

import {
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Grid,
  GridItem,
} from "@chakra-ui/react";

const alertInitialState = {
  state: false,
  type: null,
  title: null,
  message: null,
};

function EditItem({ close, item }) {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(alertInitialState);
  const formRef = useRef(null);

  const { data, count } = useContext(DataContext);

  const [descriptionValue, setDescriptionValue] = useState(item.description);
  const [oderError, setOderError] = useState(false);
  const [dateError, setDateError] = useState(false);

  function saveData(data) {
    const docRef = doc(db, "promotions", item.id);
    return updateDoc(docRef, data);
  }

  async function updateData(order) {
    if (order < item.order) {
      let filteredData = data.filter((el) => {
        return el.order >= order && el.order <= item.order && item.id !== el.id;
      });

      for (let el of filteredData) {
        const docRef = doc(db, "promotions", el.id);
        await updateDoc(docRef, { order: parseInt(el.order) + 1 });
      }
    }

    if (order > item.order) {
      let filteredData = data.filter((el) => {
        return el.order <= order && el.order >= item.order && item.id !== el.id;
      });

      for (let el of filteredData) {
        const docRef = doc(db, "promotions", el.id);
        await updateDoc(docRef, { order: parseInt(el.order) - 1 });
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setAlert(false);
    setLoading(true);
    setDateError(false);
    setOderError(false);

    const { title, subtitle, start, end, value, valueText, order } =
      e.target.elements;

    let limit = count + 2;
    let newOrder = parseInt(order.value);
    let updateAlert;

    if (isNaN(newOrder)) {
      updateAlert = {
        state: true,
        type: "error",
        title: "Error:",
        message: "Ingrese un número",
      };
      setLoading(false);
      setOderError(true);
      setAlert(updateAlert);
      return;
    }

    if (newOrder === 0) {
      updateAlert = {
        state: true,
        type: "error",
        title: "Error:",
        message: "Ingrese un número mayor a 0",
      };
      setLoading(false);
      setOderError(true);
      setAlert(updateAlert);
      return;
    }

    if (newOrder >= limit) {
      updateAlert = {
        state: true,
        type: "error",
        title: "Error:",
        message: `Ingrese un número menor a ${limit}`,
      };
      setLoading(false);
      setOderError(true);
      setAlert(updateAlert);
      return;
    }

    if (start.value > end.value) {
      updateAlert = {
        state: true,
        type: "error",
        title: "Error:",
        message: `El inicio de la promoción no puede ser posterior a su finalización`,
      };
      setLoading(false);
      setDateError(true);
      setAlert(updateAlert);
      return;
    }

    const newData = {
      public: true,
      order: newOrder,
      title: title.value,
      subtitle: subtitle.value,
      start: start.value,
      end: end.value,
      value: value.value,
      valueText: valueText.value,
      description: descriptionValue,
      state: item.state,
    };

    try {
      if (newOrder !== item.order) await updateData(newOrder);
      await saveData(newData);
      setLoading(false);
      updateAlert = {
        state: true,
        type: "success",
        title: "",
        message: `Promoción editada correctamente`,
      };
      setAlert(updateAlert);
    } catch (error) {
      updateAlert = {
        state: true,
        type: "error",
        title: "Error:",
        message: error.code,
      };
      setLoading(false);
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} style={{ width: "100%" }} ref={formRef}>
        <Heading fontSize="lg" pt={12} mb={6}>
          Agregar Promoción
        </Heading>
        <Grid templateColumns="1fr 4fr" gap={4}>
          <GridItem>
            <FormControl isRequired isInvalid={oderError}>
              <FormLabel fontSize="xs">Orden:</FormLabel>
              <Input
                rounded={4}
                size="md"
                type="text"
                name="order"
                mb={3}
                defaultValue={item.order}
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isRequired>
              <FormLabel fontSize="xs">Título de la Promoción:</FormLabel>
              <Input
                rounded={4}
                size="md"
                type="text"
                name="title"
                mb={3}
                defaultValue={item.title}
              />
            </FormControl>
          </GridItem>
        </Grid>

        <FormControl isRequired>
          <FormLabel fontSize="xs">Subtítulo:</FormLabel>
          <Input
            rounded={4}
            size="md"
            type="text"
            name="subtitle"
            mb={3}
            defaultValue={item.subtitle}
          />
        </FormControl>

        <Grid templateColumns="2fr 4fr" gap={4}>
          <GridItem>
            <FormControl isRequired>
              <FormLabel fontSize="xs">Promoción:</FormLabel>
              <Input
                rounded={4}
                size="md"
                type="text"
                name="value"
                mb={3}
                defaultValue={item.value}
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isRequired>
              <FormLabel fontSize="xs">Promoción texto:</FormLabel>
              <Input
                rounded={4}
                size="md"
                type="text"
                name="valueText"
                mb={3}
                defaultValue={item.valueText}
              />
            </FormControl>
          </GridItem>
        </Grid>

        <Grid templateColumns="1fr 1fr" gap={2}>
          <GridItem>
            <FormControl isRequired isInvalid={dateError}>
              <FormLabel fontSize="xs">Inicia:</FormLabel>
              <Input
                rounded={4}
                size="md"
                type="date"
                name="start"
                mb={3}
                defaultValue={item.start}
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isRequired isInvalid={dateError}>
              <FormLabel fontSize="xs">Finaliza:</FormLabel>
              <Input
                rounded={4}
                size="md"
                type="date"
                name="end"
                mb={3}
                defaultValue={item.end}
              />
            </FormControl>
          </GridItem>
        </Grid>

        <FormLabel fontSize="xs">Descripción:</FormLabel>
        <TextEditor
          setDescriptionValue={setDescriptionValue}
          descriptionValue={descriptionValue}
        />

        {alert.state ? (
          <Alert status={alert.type} mt={3}>
            <AlertIcon />
            <AlertTitle>{alert.title}</AlertTitle>
            <AlertDescription>{alert.message}</AlertDescription>
          </Alert>
        ) : null}
        <Flex mt={3} justifyContent="end">
          <Button
            size="sm"
            onClick={close}
            colorScheme="blue"
            mr={12}
            variant="link"
          >
            Cancelar
          </Button>
          <Button
            rounded={4}
            size="sm"
            type="submit"
            colorScheme="blue"
            p={2}
            loadingText="Agregando Promoción"
            isLoading={loading}
          >
            Actualizar Promoción
          </Button>
        </Flex>
      </form>
    </>
  );
}

export default EditItem;
