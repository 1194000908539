import { useState, useContext } from "react";
import { DataContext } from "../context/Data";
import { db } from "../lib/firebase";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import { IconButton } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

function DeleteItem({ item }) {
  const [loading, setLoading] = useState(false);

  const { data, count } = useContext(DataContext);

  async function updateData(order) {
    if (order === count) return;

    let filteredData = data.filter((el) => el.order > order);

    for (let el of filteredData) {
      const docRef = doc(db, "promotions", el.id);
      await updateDoc(docRef, { order: parseInt(el.order) - 1 });
    }
  }

  async function handleClick() {
    setLoading(true);
    const docRef = doc(db, "promotions", item.id);
    try {
      await deleteDoc(docRef);
      await updateData(item.order);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <>
      <IconButton
        rounded={2}
        onClick={handleClick}
        size="md"
        colorScheme="red"
        icon={<DeleteIcon />}
        isLoading={loading}
      ></IconButton>
    </>
  );
}

export default DeleteItem;
