import { Flex, Spinner } from "@chakra-ui/react";

function Loading() {
  return (
    <Flex
      width="100%"
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.100"
        color="blue.500"
        size="lg"
      />
    </Flex>
  );
}

export default Loading;