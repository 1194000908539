import { useState, useEffect } from "react";
import { db } from "../lib/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { IconButton } from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";

function StatusItem({ id, state }) {
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(state);

  async function handleClick() {
    setLoading(true);
    const docRef = doc(db, "promotions", id);
    try {
      await updateDoc(docRef, { state: update });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    if (state === 0) {
      setUpdate(1);
    } else {
      setUpdate(0);
    }
  }, [state]);

  return (
    <>
      <IconButton
        rounded={2}
        onClick={handleClick}
        size="md"
        colorScheme="blue"
        icon={<RepeatIcon />}
        isLoading={loading}
      >
      </IconButton>
    </>
  );
}

export default StatusItem;
