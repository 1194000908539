import { useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function TextEditor({ descriptionValue, setDescriptionValue }) {
  function handleChange(e, editor) {
    const data = editor.getData();
    setDescriptionValue(data);
  }

  useEffect(() => {
    if (descriptionValue === "") {
    }
  }, [descriptionValue]);

  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        onChange={handleChange}
        data={descriptionValue}
        config={{
          toolbar: ["bold", "italic"],
        }}
      />
    </>
  );
}

export default TextEditor;