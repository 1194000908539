import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAGayA3UpS9iXBnIe0rvMbs39WYn8ulghM",
  authDomain: "fcd-simplespa.firebaseapp.com",
  projectId: "fcd-simplespa",
  storageBucket: "fcd-simplespa.appspot.com",
  messagingSenderId: "527444373718",
  appId: "1:527444373718:web:8c63cfc7b0383e70c8d977",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();

export { app, auth, db };
