import { createContext, useEffect, useContext, useState } from "react";
import { AuthContext } from "./Auth";
import { db } from "../lib/firebase";
import Loading from "../components/Loading";

import { onSnapshot, query, collection, orderBy } from "firebase/firestore";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (currentUser) {
      const q = query(collection(db, "promotions"), orderBy("order", "asc"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let docs = [];
        
        querySnapshot.forEach((doc) => {
          let snap = doc.data();
          docs.push({
            ...snap,
            id: doc.id,
          });
        });
        setCount(docs.length)
        setData(docs);
        setPending(false);
      });
      return () => unsub();
    }
  }, [currentUser]);

  if (pending) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <DataContext.Provider value={{ data, count }}>{children}</DataContext.Provider>
  );
};
