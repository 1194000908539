import { auth } from "../lib/firebase";
import { signOut } from "firebase/auth";
import { Button } from "@chakra-ui/react";

function SignOut() {

  function SignOut(e) {
    e.preventDefault();
    signOut(auth);
  }
  
  return (
  
      <Button
        onClick={SignOut}
        colorScheme="blue"
        variant="solid"
        size="sm"
        rounded="2"
      >
        Salir
      </Button>
  );
}

export default SignOut;
