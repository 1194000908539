import { Tag } from "@chakra-ui/react";

function Label({ label }) {
  if (label === 1) {
    return (
      <Tag size="sm" colorScheme="green">
        Activada
      </Tag>
    );
  }

  return (
    <Tag size="sm" colorScheme="red">
      Desactivada
    </Tag>
  );
}

export default Label;
