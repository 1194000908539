import { useContext } from "react";
import { AuthContext } from "./context/Auth";
import { DataProvider } from "./context/Data";

import { Routes, Route } from "react-router-dom";

import Dashboard from "./Dashboard";
import Login from "./Login";

function App() {
  const { currentUser } = useContext(AuthContext);
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            currentUser ? (
              <DataProvider>
                <Dashboard />
              </DataProvider>
            ) : (
              <Login />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
